import { FunctionComponent, ReactNode } from 'react'
import styled, { css } from 'styled-components'
import { desktopCSS, layout, mobileAndTabletCSS } from '@measures/responsive'
import translate from '@i18n'

const {
  header: {
    desktop: { totalOffset: headerTotalOffsetDesktop },
    mobileAndTablet: { totalOffset: headerTotalOffsetMobileAndTablet },
  },
} = layout

interface ErrorContentProps {
  statusCode: '500' | '404'
  children?: ReactNode
}

const Wrapper = styled.div`
  display: grid;
  align-content: flex-start;
  grid-template-columns: minmax(0, 1fr);
  grid-gap: 20px;
  box-sizing: border-box;
  padding-top: 40px;

  ${desktopCSS(css`
    min-height: calc(100vh - ${headerTotalOffsetDesktop}px);
  `)};

  ${mobileAndTabletCSS(css`
    min-height: calc(100vh - ${headerTotalOffsetMobileAndTablet}px);
  `)};
`

const StatusMessage = styled.div`
  ${({
    theme: {
      color: {
        primary: { blickRed },
      },
      typography: {
        headings: {
          xlarge: { bundledCSS: headingXLargeBundledCSS },
        },
      },
    },
  }) => css`
    color: ${blickRed};
    text-align: center;
    ${headingXLargeBundledCSS};
  `}
`

const ErrorMessage = styled.div`
  ${({
    theme: {
      color: {
        primary: { primary01 },
      },
      typography: {
        headings: {
          medium: { bundledCSS: mediumHeadingBundledCSS },
        },
      },
    },
  }) => css`
    color: ${primary01};
    text-align: center;
    ${mediumHeadingBundledCSS};
  `}
`

const HelpMessage = styled.div`
  ${({
    theme: {
      color: {
        primary: { primary01 },
      },
      typography: {
        bodycopy: {
          small2: { bundledCSS: bodyCopySmall2BundledCSS },
        },
      },
    },
  }) => css`
    color: ${primary01};
    padding-top: 57px;
    width: 350px;
    margin: 0 auto;
    text-align: center;
    ${bodyCopySmall2BundledCSS};

    ${mobileAndTabletCSS(css`
      padding-top: 33px;
      width: 80vw;
    `)};
  `}
`

const ErrorContent: FunctionComponent<ErrorContentProps> = ({ statusCode }) => (
  <Wrapper>
    <StatusMessage>
      {translate('errorPage.errorStatus', { errorStatus: statusCode })}
    </StatusMessage>
    <ErrorMessage>
      {translate(
        `errorPage.${
          statusCode === '404' ? 'pageNotFound' : 'unknownErrorMessage'
        }`
      )}
    </ErrorMessage>
    <HelpMessage>{translate('errorPage.helpMessage')}</HelpMessage>
  </Wrapper>
)

export default ErrorContent
