import Footer from '@components/Footer'
import Header from '@components/Header'
import ErrorContent from '@components/ErrorContent/ErrorContent'
import { ErrorPageServerProps, getProps } from '@utils/errorPage'
import { GetStaticProps, InferGetStaticPropsType } from 'next'
import router from 'next/router'
import { FunctionComponent, useEffect } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import {
  INITIAL_VIEWPORT_ORIENTATION,
  INITIAL_DIMENSIONS,
  INITIAL_VIEWPORT_TYPE,
} from '@hooks/useViewport/utils'
import Head from 'next/head'

const FourOhFour: FunctionComponent<
  InferGetStaticPropsType<typeof getStaticProps>
> = ({ skeletonData, topics }) => {
  const queryClient = useQueryClient()

  if (!!skeletonData) {
    queryClient.setQueryData(
      ['navigationSkeleton'],
      skeletonData.skeletonData[0].navigation
    )
    queryClient.setQueryData(
      ['footerSkeleton'],
      skeletonData.skeletonData[0].footer
    )
    queryClient.setQueryData(['page'], {
      metadata: { breadcrumbs: [], contextId: '' },
      topics: topics,
    })
  }
  queryClient.setQueryData(['viewportType'], INITIAL_VIEWPORT_TYPE)
  queryClient.setQueryData(['clientViewportType'], 'server')
  queryClient.setQueryData(
    ['viewportOrientation'],
    INITIAL_VIEWPORT_ORIENTATION
  )
  queryClient.setQueryData(['viewportDimensions'], INITIAL_DIMENSIONS)

  useEffect(() => {
    router.beforePopState(({ as }) => {
      if (as !== router.asPath) {
        // This will run when leaving the 404 page pressing the back button
        // In this case we should perform a full reload of the server side rendered page
        // Otherwise we get errors accessing window obejct
        router.reload()
      }
      return true
    })

    return () => {
      router.beforePopState(() => true)
    }
  }, [])

  return (
    <>
      <Head>
        <meta
          key="cXenseParse"
          name="cXenseParse:recs:recommendable"
          content="false"
        />
      </Head>
      {!!skeletonData && <Header />}
      <ErrorContent statusCode="404" />
      {!!skeletonData && <Footer />}
    </>
  )
}

export default FourOhFour

export const getStaticProps: GetStaticProps<
  ErrorPageServerProps
> = async () => {
  const props = await getProps()
  return {
    props,
  }
}
